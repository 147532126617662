import { useLocation, useNavigate } from "react-router-dom";

export const usePageNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;

  const goToPreviousPage = (defautRoute?: string) => {
    if (from) navigate(from);
    else if (defautRoute) navigate(defautRoute);
  };

  return { goToPreviousPage };
};
