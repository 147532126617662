import { Base, BaseBold, BaseSmall, Info } from "@atoms/text";
import { ProductType } from "@features/products/types";

//À rendre modulaire si accepté
export const StockTab = (props: { product: ProductType }) => {
  return (
    <div className="grow overflow-hidden text-white p-1 rounded-lg dark:bg-slate-900">
      <table className="w-full divide-y divide-slate-400">
        <thead className="text-left ">
          <tr className="bg-slate-100">
            <th className="px-1 py-1 dark:bg-slate-900">
              <BaseBold>En stock</BaseBold>
            </th>
            <td className="bg-slate-100 px-8 dark:bg-slate-900"></td>
            <th className="px-2 py-1 text-right dark:bg-slate-900">
              <BaseBold>{props.product.qteStock}</BaseBold>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-slate-300">
          <tr>
            <td className="px-2 py-1">
              <BaseSmall>Qté affectée client</BaseSmall>
            </td>
            <td className="px-2 py-1 text-right">
              <Base>{props.product.qteAffecCli}</Base>
            </td>
          </tr>
          <tr className="">
            <td className="px-2 py-1">
              <BaseSmall>Qté expé. sortie et transfert</BaseSmall>
            </td>
            <td className="px-2 py-1 text-right">
              <Base>{props.product.qteSortPlusTrans}</Base>
            </td>
          </tr>
          <tr>
            <td className="px-2 py-1">
              <BaseSmall>Qté en prep. retour et transfert</BaseSmall>
            </td>
            <td className="px-2 py-1 text-right">
              <Base>{props.product.qtePrepRetPlusTrans}</Base>
            </td>
          </tr>
          <tr className="">
            <td className="px-2 py-1">
              <BaseSmall>Qté vente jour</BaseSmall>
            </td>
            <td className="px-2 py-1 text-right">
              <Base>{props.product.qteVenteJour}</Base>
            </td>
          </tr>
          <tr className="bg-slate-100 dark:bg-slate-900">
            <td className="px-1 py-1 font-bold">
              <BaseBold>Disponible à la vente</BaseBold>
            </td>
            <td className="h-full bg-slate-100 px-8 dark:bg-slate-900 text-center">
              <Info className="h-full flex items-center">
                Groupe ({props.product.qteDispoVenteGrp})
              </Info>
            </td>
            <td className="px-2 py-1 text-right">
              <BaseBold>{props.product.qteDispoVente}</BaseBold>
            </td>
          </tr>
          <tr className="">
            <td className="px-2 py-1 dark:bg-slate-900">
              <BaseSmall>Attendus commande et transfert</BaseSmall>
            </td>
            <td className="px-8 "></td>
            <td className="px-2 py-1 text-right">
              <Base>{props.product.qteCdeFournPlusTrans}</Base>
            </td>
          </tr>
          <tr>
            <td className="px-2 py-1">
              <BaseSmall>dont Qté réservée Client</BaseSmall>
            </td>
            <td className="px-2 py-1 text-right">
              <Base>{props.product.qteReservCli}</Base>
            </td>
          </tr>

          <tr className="bg-slate-100 dark:bg-slate-900">
            <td className="px-1 py-1 font-bold">
              <BaseBold>Disponible à la vente future</BaseBold>
            </td>
            <td className="bg-slate-100 px-8 dark:bg-slate-900"></td>
            <td className="px-2 py-1 text-right ">
              {/* <BaseBold>{props.product.qteDispoVenteFuturFutur}</BaseBold> */}
              <BaseBold>{props.product.qteDispoVenteFutur}</BaseBold>
            </td>
          </tr>
          <tr className="">
            <td className="px-2 py-1 dark:bg-slate-900">
              <BaseSmall>En préparation de commande</BaseSmall>
            </td>
            <td className="px-8 "></td>
            <td className="px-2 py-1 text-right">
              <Base>{props.product.qtePrepCdePlusTrans}</Base>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
