import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, Location } from "react-router-dom";

export type NavigationContextType = {
  from?: Location;
  to: Location;
};

export const NavigationContext = createContext<
  NavigationContextType | undefined
>(undefined);

type NavigationProviderProps = {
  children: React.ReactNode;
};

const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
}) => {
  const location = useLocation();
  const previousLocationRef = useRef<Location | undefined>(undefined);
  const [from, setFrom] = useState<Location | undefined>(undefined);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (previousLocationRef.current) {
      setFrom(previousLocationRef.current);
    }

    previousLocationRef.current = location;
  }, [location]);

  const contextValue = { from, to: location };

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(
      "useNavigation doit être utilisé dans un <NavigationProvider>"
    );
  }
  return context;
};

export default NavigationProvider;
