import { CustomerMode } from "@features/customer/state/store";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { useProduct } from "@features/products/state/use-product";
import { Book } from "@features/products/types";
import { SearchFormFieldType } from "@molecules/form/types";
import { ConditionAchatModalAtom } from "@views/client/products/product/sublists/conditions-achat";
import { SuppliersModalAtom } from "@views/client/products/product/sublists/suppliers";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

export const BookForm = (product: Book | null) => {
  const { ean } = useParams<{ ean: string }>();
  const { getFieldSuggestions } = useProduct(ean || "");
  const { current } = useShopLocations();
  const openSuppliersModal = useSetRecoilState(SuppliersModalAtom);
  const openConditionAchatModal = useSetRecoilState(ConditionAchatModalAtom);
  const customerMode = useRecoilValue(CustomerMode);

  const general: SearchFormFieldType[] = [
    {
      key: "auteu",
      label: t("products.livre.auteur"),
      type: "text",
    },
    {
      key: "editeur",
      label: t("products.livre.editeur"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "dateParu",
      label: t("products.livre.dateParu"),
      type: "date",
    },
    {
      key: "libRayon",
      label: t("products.livre.libRayon"),
      type: "text",
    },
    {
      key: "id",
      label: "EAN",
      type: "text",
    },
  ];

  const identification: SearchFormFieldType[] = [
    {
      key: "diffuseur",
      label: t("products.livre.identification.diffuseur"),
      type: "text",
      options: async (query) => await getFieldSuggestions(query),
    },
    {
      key: "codeEdit",
      label: t("products.livre.identification.refEditeur"),
      type: "searchselect",
      options: async (query) => {
        return await getFieldSuggestions(query);
      },
    },
    {
      key: "indexe",
      label: t("products.livre.identification.matchCode"),
      type: "text",
    },
    {
      key: "titreColl",
      label: t("products.livre.identification.collection"),
      type: "text",
      options: async (query) => await getFieldSuggestions(query),
    },
    {
      key: "numColl",
      label: t("products.livre.identification.numeroCollection"),
      type: "text",
    },
    {
      key: "tome",
      label: t("products.livre.identification.tome"),
      type: "number",
      min: 0,
      max: 999,
    },
    {
      key: "poche",
      label: t("products.livre.identification.poche"),
      type: "boolean",
    },

    {
      key: "refInt",
      label: t("products.livre.identification.refInterne"),
      type: "text",
    },

    {
      key: "commInt",
      label: t("products.livre.identification.commInterne"),
      type: "text",
    },
    {
      key: "nbrPage",
      label: t("products.livre.identification.pages"),
      type: "number",
    },
    {
      key: "ean13Precd",
      label: t("products.livre.identification.eanPrec"),
      type: "scan",
    },
    {
      key: "ean13Regrp",
      label: t("products.livre.identification.eanRegroup"),
      type: "scan",
    },
    {
      key: "ean13Subst",
      label: t("products.livre.identification.eanSubst"),
      type: "scan",
    },
    {
      key: "ean13Detail",
      label: t("products.livre.identification.eanDetail"),
      type: "scan",
    },
    {
      key: "ean13Neuf",
      label: t("products.livre.identification.eanNeuf"),
      type: "scan",
    },
    {
      key: "idBD",
      label: "Id BD",
      type: "text",
    },
    {
      key: "comm",
      label: t("products.livre.identification.resume"),
      type: "html",
      alwaysVisible: true,
    },
  ];

  const classification: SearchFormFieldType[] = [
    {
      key: "indicScol",
      label: t("products.livre.classification.scolaire"),
      type: "boolean",
      alwaysVisible: true,
    },
    {
      key: "passCulture",
      label: t("products.livre.identification.passCulture"),
      type: "boolean",
      alwaysVisible: true,
    },
    {
      key: "theme",
      label: t("products.livre.classification.theme"),
      type: "text",
      options: async (query) => await getFieldSuggestions(query),
    },
    {
      key: "pays",
      label: t("products.livre.classification.pays"),
      type: "text",
      options: [
        { label: "FR - FRANCE", value: "FR - FRANCE" },
        { label: "JP - JAPON", value: "JP - JAPON" },
      ], // Encore à déterminer
    },
  ];

  const venteGeneral: SearchFormFieldType[] = [
    {
      key: "codePrix",
      label: t("products.livre.vente.codePrix"),
      type: "text",
    },
    {
      key: "prixPublic",
      label: t("products.livre.vente.prixPublic"),
      type: "formatted",
      format: "price",
      currencySymb: product?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "tauxTaxe1",
      label: t("products.livre.vente.tva1"),
      type: "formatted",
      format: "percentage",
    },
    {
      key: "prixVenteHT2",
      label: t("products.livre.vente.pvHT2"),
      type: "formatted",
      format: "price",
      currencySymb: product?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "tauxTaxe2",
      label: t("products.livre.vente.tva2"),
      type: "formatted",
      format: "percentage",
    },
    {
      key: "gerePrixAchat",
      label: t("products.livre.vente.gererPrixAchat"),
      type: "text",
      hidden: customerMode,
    },
  ];

  const venteCanal: SearchFormFieldType[] = [
    {
      key: "prixVenteRef",
      label: t("products.livre.vente.prixRef"),
      type: "formatted",
      format: "price",
      currencySymb: product?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "prixVente",
      label: t("products.livre.vente.prixVente"),
      type: "formatted",
      format: "price",
      currencySymb: product?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "qteTotVente",
      label: "Quantité totale vente",
      type: "number",
      alwaysVisible: true,
    },
    {
      key: "refVente",
      label: t("products.livre.vente.refVente"),
      type: "text",
    },
    {
      key: "Promo",
      label: t("products.livre.vente.promotion"),
      type: "text",
    },
    {
      key: "dateDebutPromo",
      label: t("products.livre.vente.debutPromotion"),
      type: "date",
    },
    {
      key: "dateFinPromo",
      label: t("products.livre.vente.finPromotion"),
      type: "date",
    },
    {
      key: "actif",
      label: t("products.livre.vente.actif"),
      type: "text",
    },
    {
      key: "datePremVente",
      label: t("products.livre.vente.premVente"),
      type: "date",
    },
    {
      key: "dateDernVente",
      label: t("products.livre.vente.dernVente"),
      type: "date",
    },
    {
      key: "plcv.qteVente",
      label: t("products.livre.vente.qtVendue"),
      type: "text",
    },
    {
      key: "marge",
      label: t("products.livre.vente.marge"),
      type: "formatted",
      format: "percentage",
      hidden: customerMode,
    },
    {
      key: "priveVenteHT",
      label: t("products.livre.vente.pvHT"),
      type: "formatted",
      format: "price",
      currencySymb: product?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
  ];

  const approvisionnement: SearchFormFieldType[] = [
    {
      key: "datePreco",
      label: t("products.livre.approvisionnement.precommandes"),
      type: "text",
    },
    {
      key: "codeEtat",
      label: t("products.livre.approvisionnement.etat"),
      type: "select",
      options: [
        { label: "Obligatoire", value: "Obligatoire" },
        { label: "Supprimé", value: "Supprimé" },
      ],
      render: (value) => {
        switch (value) {
          case "S":
            return "Supprimé";
          case "O":
            return "Obligatoire";
          default:
            return value;
        }
      },
    },
    {
      key: "emplac",
      label: t("products.livre.approvisionnement.emplacement"),
      type: "text",
    },
    {
      key: "qteStockMini",
      label: t("products.livre.approvisionnement.min"),
      type: "text",
    },
    {
      key: "qteStockMaxi",
      label: t("products.livre.approvisionnement.max"),
      type: "text",
    },
    {
      key: "fourn",
      label: t("products.livre.approvisionnement.fournRef"),
      type: "text",
      options: async (query) => {
        const result = await getFieldSuggestions(query);
        return result as [];
      },
    },
    {
      key: "dispo",
      label: t("products.livre.approvisionnement.dispoFref"),
      type: "text",
    },
    {
      key: "fournHabit",
      label: t("products.livre.approvisionnement.fournHabit"),
      type: "text",
      options: async (query) => {
        const result = await getFieldSuggestions(query);
        return result as [];
      },
    },
    {
      key: "dispoHabit",
      label: t("products.livre.approvisionnement.dispoFHabit"),
      type: "text",
    },
    {
      key: "internetPres",
      label: t("products.livre.approvisionnement.presentInternet"),
      type: "text",
    },
    {
      key: "internetRejet",
      label: t("products.livre.approvisionnement.rejetInternet"),
      type: "text",
    },
    {
      key: "internetRetir",
      label: t("products.livre.approvisionnement.retireInternet"),
      type: "text",
    },
    {
      key: "listConditionAchat",
      label: t("products.livre.approvisionnement.conditionsAchat"),
      type: "modal",
      hidden: customerMode,
      alwaysVisible: true,
      render: () => `(${(product?.listConditionAchat || []).length})`,
      onClick: ({ readonly, values }: { readonly?: boolean; values: Book }) => {
        openConditionAchatModal({
          type: "book",
          id: product?.id || "",
          readonly: !!readonly,
          data: product?.listConditionAchat || [],
        });
      },
    },
    {
      key: "listFourn",
      label: t("products.livre.approvisionnement.fournisseurs"),
      type: "modal",
      render: (_value, values: Book) =>
        values.listFourn
          ?.map((a) => a.raisSocial + ` (${a.codeFourn})`)
          .join(", "),
      onClick: ({ readonly, values }: { readonly?: boolean; values: Book }) =>
        openSuppliersModal({
          type: "book",
          id: values.id,
          readonly: !!readonly,
          data: values.listFourn,
        }),
    },
    {
      key: "Par combien",
      label: "Par combien",
      type: "select",
    },
  ];

  const stock: SearchFormFieldType[] = [
    {
      key: "epais",
      label: t("products.livre.stock.epaisseur"),
      type: "number",
    },
    {
      key: "haut",
      label: t("products.livre.stock.hauteur"),
      type: "number",
    },
    {
      key: "larg",
      label: t("products.livre.stock.largeur"),
      type: "number",
    },
    {
      key: "poids",
      label: t("products.livre.stock.poids"),
      type: "number",
    },
    {
      key: "dateDernCde",
      label: t("products.livre.stock.dernCommande"),
      type: "date",
    },
    {
      key: "dateDernMvt",
      label: t("products.livre.stock.dernMouvement"),
      type: "date",
    },
    {
      key: "dateDernRecep",
      label: t("products.livre.stock.dernReception"),
      type: "date",
    },
    {
      key: "dateDernRet",
      label: t("products.livre.stock.dernRetour"),
      type: "date",
    },
    {
      key: "datePremEntre",
      label: t("products.livre.stock.premEntree"),
      type: "date",
    },
    {
      key: "dateDernRupt",
      label: t("products.livre.stock.dernRupture"),
      type: "date",
    },
    {
      key: "dateDernInven",
      label: t("products.livre.stock.dernInventaire"),
      type: "date",
    },
    {
      key: "dateEntreeMP",
      label: t("products.livre.stock.entreMoyenPond"),
      type: "date",
    },
    {
      key: "dateDernRe",
      label: t("products.livre.stock.dernPaNet"),
      type: "date",
    },
    {
      key: "dernPaNetHT",
      label: t("products.livre.stock.dernPaNetNet"),
      type: "formatted",
      format: "price",
      currencySymb: product?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "dernPaNetNetHT",
      label: "Dern PA net net HT",
      type: "formatted",
      format: "price",
      currencySymb: product?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
      hidden: customerMode,
    },
    {
      key: "pampNetHT",
      label: t("products.livre.stock.pampNet"),
      type: "formatted",
      format: "price",
      currencySymb: product?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
      hidden: customerMode,
    },
    {
      key: "pampNetNetHT",
      label: t("products.livre.stock.pampNetNet"),
      type: "formatted",
      format: "price",
      currencySymb: product?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
      hidden: customerMode,
    },
    {
      key: "numZone",
      label: "Zone",
      type: "text",
      hidden: customerMode,
    },
  ];

  return {
    general,
    identification,
    classification,
    venteGeneral,
    venteCanal,
    approvisionnement,
    stock,
  };
};
