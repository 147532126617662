import { useShortcuts } from "@features/utils/shortcuts";
import { MdOutlineOpenInNewOff } from "react-icons/md";
import _ from "lodash";
import React from "react";
import { InputProps } from "./input-text";

export const defaultInputClassName = (theme: "plain" = "plain") => {
  return "rounded-lg shadow-sm focus:ring-blue-600 focus:border-blue-600 block w-full text-sm border-gray-200 dark:bg-slate-900 dark:border-slate-700 dark:text-white dark:focus:ring-sky-900 dark:focus:border-sky-900";
};

export const errorInputClassName = (theme: "plain" = "plain") => {
  return (
    defaultInputClassName(theme) +
    " bg-red-50 border-red-300 dark:bg-red-900 dark:border-red-800"
  );
};

export const InputTab = ({
  highlight,
  theme,
  label,
  size,
  feedback,
  hasError,
  autoSelect = true, // valeur par défaut
  multiline,
  inputComponent,
  inputClassName,
  className,
  inputRef: propInputRef,
  shortcut,
  ...rest
}: InputProps) => {
  let combinedInputClassName = hasError
    ? errorInputClassName(theme)
    : defaultInputClassName(theme);
  combinedInputClassName += rest.disabled ? " opacity-25" : "";

  const internalRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const inputRef = propInputRef || internalRef;

  const handleInputClick = (event: any) => {
    // Appelez d'abord la fonction onClick passée en props, le cas échéant
    if (rest.onClick) {
      rest.onClick(event);
    }
  };

  useShortcuts(!rest.disabled && shortcut?.length ? [...shortcut] : [], (e) => {
    (inputRef as any)?.current?.focus();
  });

  return (
    <>
      <div className="group relative cursor-pointer">
        <input
          className={
            "cursor-pointer select-none hover:text-blue-600 " +
            combinedInputClassName +
            " " +
            inputClassName +
            " " +
            className
          }
          style={{ userSelect: "none" }}
          {..._.omit(
            rest,
            "label",
            "inputRef",
            "inputClassName",
            "className",
            "size",
            "highlight",
            "type"
          )}
          onMouseDown={(e) => e.preventDefault()}
          onSelect={(e) => e.preventDefault()}
          onClick={(e) => {
            //e.preventDefault();
            handleInputClick(e);
          }}
        />

        <MdOutlineOpenInNewOff
          className="h-5 w-5 text-slate-500 opacity-80 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer group-hover:text-blue-500"
          onClick={(e) => {
            handleInputClick(e);
          }}
        />
      </div>
    </>
  );
};
