import { Modal, ModalContent } from "@atoms/modal/modal";
import { dataLibrairie } from "@views/client/dashboard/fakeData";
import { atom, useRecoilState } from "recoil";
import {
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
  BarElement,
  BarController,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Button } from "@atoms/button/button";
import { CheckIcon } from "@heroicons/react/outline";
import { Frame } from "@atoms/layout/frame";
import { BaseBold, InfoSmallBold, Section } from "@atoms/text";
import { ReassortProductLine } from "@features/supplier/types";
import { GraphTab } from "@components/product/productGraphTab";
import { InputCounter } from "@atoms/input/input-counter";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import toast from "react-hot-toast";
import { Input } from "@atoms/input/input-text";
import { useAuth } from "@features/general/auth/state/use-auth";
import { useState } from "react";
import { focusInput } from "@features/utils/divers";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { ReasPagination } from "@features/supplier/state/store";
import { GenericBasicTable } from "@molecules/generic-basic-table";
import { Form } from "@molecules/form";
import { SearchFormFieldType } from "@molecules/form/types";
import { useProduct } from "@features/products/state/use-product";
import { PeriodPicking } from "@components/product/product-graph";
import { BookRender } from "@atoms/book";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  ArcElement,
  LineElement,
  LineController,
  PointElement,
  ChartDataLabels,
  Tooltip,
  Legend
);

type ProductGraphModalType = {
  open: boolean;
  product?: ReassortProductLine;
  modeGest?: string;
  codeTypeProd?: string;
  pageNumber?: number;
  graphSelection?: { timePeriod: PeriodPicking; canalVente: string };
};

export const chartData = {
  labels: dataLibrairie.map((el) => el.mois),
  datasets: [
    {
      type: "line" as const,
      label: "Ventes",
      borderColor: "#06b6d4",
      borderWidth: 3,
      fill: false,
      data: dataLibrairie.map((el) => el.revenus),
    },
  ],
};

export const ProductGraphModalAtom = atom<ProductGraphModalType>({
  key: "ProductGraphModalAtom",
  default: { open: false, product: undefined },
});

export const ProductGraphModal = () => {
  const [productGraphModal, setProductGraphModal] = useRecoilState(
    ProductGraphModalAtom
  );
  const { product: fullProduct, refresh } = useProduct(
    productGraphModal.product?.ean13 || "",
    undefined,
    productGraphModal.product?.tooltip.modeGest || ""
  );
  const [waitRank, setWaitRank] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useRecoilState(ReasPagination);
  const { user } = useAuth();

  const {
    reassortLines,
    updateReassortLine,
    setReassortLines,
    getReassortLines,
    setReassortLines: changeReassortLines,
  } = useSuppliers();

  const handleClose = () => {
    setProductGraphModal((previous) => {
      return {
        ...previous,
        open: false,
        product: undefined,
        pageNumber: previous.pageNumber,
        graphSelection: undefined,
      };
    });
  };

  useControlledEffect(() => {
    if (productGraphModal.open) {
      const index = reassortLines.items.findIndex(
        (l) => l.ean13 === productGraphModal.product?.ean13
      );
      if (index !== -1) setWaitRank(index);
      setPageNumber(reassortLines.pagination.pageNumber);
      setProductGraphModal({
        ...productGraphModal,
        pageNumber: reassortLines.pagination.pageNumber,
      });
    } else {
      setWaitRank(0);
      setPageNumber(1);
      setProductGraphModal({
        ...productGraphModal,
      });
    }
    setTimeout(() => {
      if (productGraphModal.open && productGraphModal.product?.ean13) {
        focusInput("#qte-counter");
      }
    }, 500);
  }, [productGraphModal.open, productGraphModal.product?.ean13]);

  useControlledEffect(() => {
    refresh();
  }, [productGraphModal.product?.ean13]);

  return (
    <Modal
      className="sm:!max-w-[1200px]"
      open={productGraphModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent
        title={
          <div className="flex gap-2 flex-row">
            <div
              className="h-full flex flex-col justify-center items-end w-8"
              data-tooltip={
                fullProduct
                  ? `<img
                src="${fullProduct.imageURL}"     
                style="max-width: 400px; max-height: 400px; width: 100%; height: auto;"/>`
                  : ""
              }
              data-position="bottom"
            >
              {fullProduct && (
                <BookRender
                  src={fullProduct.imageURL}
                  productType={fullProduct.modeGest}
                />
              )}
            </div>
            <Section className="flex flex-col justify-center ">
              {`Informations sur: ${
                productGraphModal.modeGest === "L"
                  ? `${productGraphModal.product?.titre} - ${productGraphModal.product?.ean13} - ${productGraphModal.product?.codeEdit}`
                  : `${productGraphModal.product?.designation} - ${productGraphModal.product?.ean13}`
              } `}
            </Section>
          </div>
        }
        // title={`Informations sur: ${
        //   productGraphModal.modeGest === "L"
        //     ? `${productGraphModal.product?.titre} - ${productGraphModal.product?.ean13} - ${productGraphModal.product?.codeEdit}`
        //     : `${productGraphModal.product?.designation} - ${productGraphModal.product?.ean13}`
        // } `}
      >
        <div className="flex flex-col gap-2 md:gap-2 h-full">
          {productGraphModal.product && fullProduct && (
            <Frame className="w-full flex flex-col gap-2">
              <div className="w-full flex justify-center">
                <GraphTab
                  product={fullProduct}
                  referGestion={true}
                  canal={productGraphModal.graphSelection?.canalVente || "CPT"}
                  timePeriod={
                    productGraphModal.graphSelection?.timePeriod ||
                    ({ lib: "J", unit: "D", range: 7 } as PeriodPicking)
                  }
                  onChangeGraphSelection={(timePeriod, canalVente) =>
                    setProductGraphModal({
                      ...productGraphModal,
                      graphSelection: {
                        timePeriod: timePeriod,
                        canalVente: canalVente,
                      },
                    })
                  }
                  type="bar"
                  graphContainerClassname="max-w-xl xl:max-w-2xl"
                  tabContainerClassname="max-w-sm xl:max-w-xl"
                />
              </div>
              <div className="w-full flex justify-center gap-4">
                <Frame className="w-full flex items-center">
                  <GenericBasicTable
                    columns={[
                      {
                        columnName: "Canal de vente",
                        columnKey: "libCanalVente",
                      },
                      {
                        columnName: "Prix de vente",
                        columnKey: "prixVente",
                        format: "price",
                      },
                      {
                        columnName: "Date de première ventre",
                        columnKey: "datePremVente",
                        format: "date",
                      },
                      {
                        columnName: "Date de dernière vente",
                        columnKey: "dateDernVente",
                        format: "date",
                      },
                      {
                        columnName: "Quantité vente",
                        columnKey: "qteTotVente",
                        format: "number",
                        total: true,
                      },
                    ]}
                    objectList={fullProduct.listPlcv}
                  />
                </Frame>
                <Frame className="min-w-80 flex flex-col gap-2">
                  <Form
                    readonly={true}
                    value={fullProduct}
                    onChange={() => {}}
                    fields={
                      [
                        {
                          key: "codeFourn",
                          label: "Fournisseur",
                          alwaysVisible: true,
                        },
                        {
                          key: "dispo",
                          label: "Disponibilité",
                          alwaysVisible: true,
                        },
                        {
                          key: "dateParu",
                          label: "Date de parution",
                          type: "date",
                          alwaysVisible: true,
                        },
                        {
                          key: "dateDernCde",
                          label: "Date dernière commande",
                          type: "date",
                          alwaysVisible: true,
                        },
                        {
                          key: "dateDernRecep",
                          label: "Date dernière réception",
                          type: "date",
                          alwaysVisible: true,
                        },
                        {
                          key: "dateDernRet",
                          label: "Date dernier retour",
                          type: "date",
                          alwaysVisible: true,
                        },
                      ] as SearchFormFieldType[]
                    }
                  />
                </Frame>
              </div>
            </Frame>
          )}
          {productGraphModal.product !== undefined && fullProduct && <></>}

          <div className="h-full w-full flex gap-2 justify-between">
            <div className="flex gap-2">
              <BaseBold className="flex flex-col justify-center">
                Quantité:
              </BaseBold>
              <div className="h-full flex flex-col justify-center max-w-44">
                <InputCounter
                  id="qte-counter"
                  size={"sm"}
                  value={
                    productGraphModal.product
                      ? productGraphModal.product.qte
                      : 0
                  }
                  min={0}
                  onChange={(newValue) => {
                    if (productGraphModal.product) {
                      const reasLine = { ...productGraphModal.product };
                      reasLine.qte = newValue;
                      setProductGraphModal({
                        ...productGraphModal,
                        product: reasLine,
                      });
                    }
                  }}
                />
              </div>
            </div>

            {productGraphModal.product && productGraphModal.product.qte > 0 && (
              <div className="flex gap-2">
                <BaseBold className="flex flex-col justify-center">
                  Panier:
                </BaseBold>
                <div className="h-full flex flex-col justify-center">
                  <Input
                    id="panier-input"
                    value={productGraphModal.product.panie}
                    onChange={(e) =>
                      setProductGraphModal({
                        ...productGraphModal,
                        product: productGraphModal.product
                          ? {
                              ...productGraphModal.product,
                              panie: e.target.value,
                            }
                          : undefined,
                      })
                    }
                    size="sm"
                    placeholder="ex: panier..."
                  />
                </div>
              </div>
            )}
            <div className="flex gap-2">
              <Button
                onClick={async () => {
                  if (
                    (reassortLines.pagination.pageNumber - 1) *
                      reassortLines.pagination.pageSize +
                      reassortLines.pagination.rowsReturned >=
                    reassortLines.pagination.totalRows
                  ) {
                    if (waitRank + 1 >= reassortLines.pagination.rowsReturned) {
                      handleClose();
                      return;
                    }
                  }
                  const newProduct = reassortLines.items[waitRank + 1];
                  if (newProduct) {
                    setProductGraphModal((previous) => {
                      return {
                        ...productGraphModal,
                        product: {
                          ...newProduct,
                          panie: previous.product?.panie || "",
                        },
                      };
                    });
                    setWaitRank((previous) => previous + 1);
                    return;
                  } else {
                    if (reassortLines.pagination.totalRows > 0) {
                      const res = await getReassortLines(
                        productGraphModal.modeGest || "",
                        productGraphModal.codeTypeProd || "",
                        {
                          pageNumber: pageNumber + 1,
                          pageSize: 100,
                          orderBy: pagination.orderById
                            ? pagination.orderById
                            : "dateDernVente", // À modifier
                          orderDir: pagination.order,
                        },
                        pagination.filters
                      );
                      if (res) {
                        setPagination({
                          ...pagination,
                          page: pageNumber + 1,
                        });
                        changeReassortLines(res);
                        setProductGraphModal((previous) => {
                          return {
                            ...previous,
                            product: {
                              ...res.items[0],
                              panie: previous.product?.panie || "",
                            },
                            pageNumber: previous.pageNumber
                              ? previous.pageNumber + 1
                              : 1,
                          };
                        });
                        setPageNumber((previous) => previous + 1);

                        setWaitRank(0);
                      }
                    } else {
                      handleClose();
                    }
                  }
                }}
                className="self-end"
              >
                Attendre
              </Button>
              <Button
                theme="valid"
                data-tooltip="Valider choix"
                shortcut={["enter"]}
                onClick={async () => {
                  if (productGraphModal.product) {
                    const res = await updateReassortLine(
                      productGraphModal.product.id,
                      productGraphModal.product.ean13,
                      productGraphModal.product.qte,
                      productGraphModal.product.panie,
                      productGraphModal.product.tooltip.codeEtat,
                      user?.id || ""
                    );
                    if (res) {
                      toast.success("Ligne mise à jour avec succès");
                      const updatedLines = await getReassortLines(
                        productGraphModal.modeGest || "",
                        productGraphModal.codeTypeProd || "",
                        {
                          pageNumber: productGraphModal.pageNumber || 1,
                          pageSize: 100,
                          orderBy: pagination.orderById
                            ? pagination.orderById
                            : "dateDernVente", // À modifier
                          orderDir: pagination.order,
                        },
                        pagination.filters
                      );
                      if (updatedLines) {
                        setReassortLines(updatedLines);
                        if (
                          (reassortLines.pagination.pageNumber - 1) *
                            reassortLines.pagination.pageSize +
                            reassortLines.pagination.rowsReturned >=
                          reassortLines.pagination.totalRows
                        ) {
                          if (
                            waitRank + 1 >=
                            reassortLines.pagination.rowsReturned
                          ) {
                            handleClose();
                            return;
                          }
                        }
                        if (updatedLines.items.length > 0) {
                          setProductGraphModal((previous) => {
                            return {
                              ...productGraphModal,
                              product: {
                                ...updatedLines.items[waitRank],
                                panie: previous.product
                                  ? previous.product.panie
                                  : "",
                              },
                            };
                          });
                        } else handleClose();
                      }
                    } else {
                      toast.error("Erreur lors de la mise à jour de la ligne");
                    }
                  }
                }}
                icon={({ className }) => <CheckIcon className={className} />}
              >
                Valider
              </Button>
              {/* <Button
                theme="danger"
                data-tooltip="Annuler choix"
                onClick={() => handleClose()}
                icon={({ className }) => <XIcon className={className} />}
              >
                Annuler
              </Button> */}
            </div>
          </div>
          <Button
            className="invisible"
            shortcut={["tab"]}
            onClick={() => {
              if (
                productGraphModal.open &&
                productGraphModal.product &&
                productGraphModal.product.qte > 0
              ) {
                const inputPanier = document.querySelector("#panier-input");
                if (inputPanier) (inputPanier as HTMLInputElement).focus();
              }
            }}
          />
        </div>
        <InfoSmallBold className="absolute bottom-0 right-3 z-60 p-4">{`${
          waitRank + 1
        }\\${reassortLines.pagination.totalRows}`}</InfoSmallBold>
      </ModalContent>
    </Modal>
  );
};
