import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { atom, useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { useOrder } from "@features/customer/state/use-order";
import toast from "react-hot-toast";
import { useBaskets } from "@features/products/state/use-baskets";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Form } from "@molecules/form";
import { useBasket } from "@features/products/state/use-basket";
import { useShopLocations } from "@features/general/auth/state/use-store-location";

export const FinalizeOrderAtom = atom({
  key: "FinalizeOrdertom",
  default: {
    open: false,
    client: {},
    updateCustomerForm: true,
    items: {},
    panie: "",
    basket: "",
    mtAcomp: 0,
    comm: "",
  },
});

export const FinalizedOrderModal = () => {
  const [finalizeOrderModal, setFinalizeOrderModal] =
    useRecoilState(FinalizeOrderAtom);
  const { loading, createOrder } = useOrder("");
  const { removeBasket } = useBaskets();
  const { getPrice } = useBasket(finalizeOrderModal.basket);
  const { current: currentShop } = useShopLocations();

  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    setFinalizeOrderModal({
      ...finalizeOrderModal,
      open: false,
      mtAcomp: 0,
      panie: "",
      comm: "",
    });
  };

  return (
    <>
      <Modal open={finalizeOrderModal.open} onClose={() => handleClose()}>
        <ModalContent title="Finaliser commande">
          <div className="flex flex-col gap-4">
            <Form
              readonly={false}
              value={finalizeOrderModal}
              onChange={(newValue) => {
                setFinalizeOrderModal({
                  ...finalizeOrderModal,
                  mtAcomp: newValue.mtAcomp,
                  panie: newValue.panie,
                  comm: newValue.comm,
                });
              }}
              fields={[
                {
                  key: "mtAcomp",
                  label: "Acompte",
                  type: "formatted",
                  format: "price",
                  currencySymb: currentShop?.devisSymb,
                  currencyNbDecim: currentShop?.devisNbDecim,
                  alwaysVisible: true,
                  min: 0,
                  max: getPrice(),
                  autoFocus: true,
                },
                {
                  key: "panie",
                  label: "Panier",
                  type: "text",
                  placeholder: "ex: Panier scolaire",
                  alwaysVisible: true,
                  maxLength: 30,
                },
                {
                  key: "comm",
                  label: "Commentaire",
                  type: "multiline",
                  placeholder: "Mettre un commentaire si besoin...",
                  alwaysVisible: true,
                },
              ]}
            />
            <Button
              className="w-auto float-right"
              disabled={loading}
              size="lg"
              shortcut={["enter"]}
              onClick={async () => {
                const result = await createOrder({
                  ...finalizeOrderModal.client,
                  items: finalizeOrderModal.items,
                  comm: finalizeOrderModal.comm,
                  panie:
                    finalizeOrderModal.panie !== ""
                      ? finalizeOrderModal.panie
                      : finalizeOrderModal.basket,
                  mtAcomp: Number(finalizeOrderModal.mtAcomp),
                  updateCustomerForm: finalizeOrderModal.updateCustomerForm,
                });
                if (result && result.numCde) {
                  toast.success("Commande effectuée");
                  removeBasket(finalizeOrderModal.basket || "");
                  handleClose();
                  navigate(
                    ROUTES.Order.replace(/:id/, result.numCde)
                      .replace(/:created/, "1")
                      .replace(/:ts/, Date.now().toString()),
                    { state: { from: location.pathname } }
                  );
                } else {
                  toast.error("Impossible de passer la commande");
                }
              }}
              icon={(p) => <CheckCircleIcon {...p} />}
            >
              Finaliser commande
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};
