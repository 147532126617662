import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Base, BaseSmall, Info, InfoSmall, Section, Title } from "@atoms/text";
import { Order, OrderItem } from "@features/customer/type";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { useBasket } from "@features/products/state/use-basket";
import { useBaskets } from "@features/products/state/use-baskets";
import { ROUTES } from "@features/routes";
import {
  formatAmount,
  getCommonProductTooltip,
} from "@features/utils/format/strings";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { Column } from "@molecules/table/table";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import _ from "lodash";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OrderProductCard from "./order-product-card";
import { InputEdit } from "@atoms/input/input-edit";
import toast from "react-hot-toast";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useOrder } from "@features/customer/state/use-order";
import { useSetRecoilState } from "recoil";
import { CommonConfirmModalAtom } from "@molecules/confirm-modal";
import { FaExclamationTriangle } from "react-icons/fa";
import Select from "@atoms/input/input-select";

const OrderReview = (props: {
  products: OrderItem[];
  onChange: (value: OrderItem[]) => void;
  order?: Order;
  readonly?: boolean;
  enableBuy?: boolean;
}) => {
  const [orderBy, setOrderBy] = useState<[number, string]>([0, "ASC"]);
  const [productList, setProductList] = useState<OrderItem[]>(props.products);
  const [listUpdate, setListUpdate] = useState(0);
  const setConfirmModal = useSetRecoilState(CommonConfirmModalAtom);
  const { current } = useBaskets();
  const { current: currentShop, functionsAuthorization } = useShopLocations();
  const {
    products: fullProducts,
    add,
    remove,
    changeStatu,
  } = useBasket(current || "");
  const {
    command: order,
    getOrderItems,
    refresh,
  } = useOrder(props.order ? props.order.numCde || "" : "");
  const { verifOrderLineMaj, orderLineMaj } = useOrder("");
  const columns = ["designation", "pxUnit", "qte", "total", "action"];
  const location = useLocation();
  const [statusList, setStatusList] = useState<
    {
      code: string;
      libelle: string;
    }[]
  >([]);
  // const [basketItems, setBasketItem] = useState<{ ean: string; qte: number }[]>(
  //   fullProducts.map((el) => {
  //     return { ean: el.product.id, qte: el.count };
  //   })
  // );
  const navigate = useNavigate();

  const handleCancelStatuChange = (orderItem: OrderItem) => {
    const pIndex = productList.findIndex(
      (el) =>
        el.numLigne === orderItem.numLigne &&
        el.numLigneDisp === orderItem.numLigneDisp
    );
    if (pIndex >= 0) {
      const pTemp = {
        ...productList[pIndex],
      };
      const tempTab = [...productList];
      tempTab[pIndex] = pTemp;
      setProductList(tempTab);
    }
  };

  const validMaj = async (
    orderID: string,
    orderItem: OrderItem,
    newStatu: string
  ) => {
    const resMAJ = await orderLineMaj(orderID, {
      ...orderItem,
      statu: newStatu,
    });
    if (resMAJ) toast.success("Modification effectuée avec succès");
  };

  useControlledEffect(() => {
    const getStatus = async () => {
      const res = await CommonApiClient.getOrderStatusList();
      if (res) setStatusList(res);
    };
    getStatus();
  }, []);

  const allColumns: Column<OrderItem>[] = [
    // {
    //   title: "",
    //   render: (orderItem, { responsive }) =>
    //     responsive && (
    //       <>
    //         <div className="w-10 print:hidden">
    //           <BookRender
    //             src={orderItem.product.imageURL}
    //             productType={orderItem.product.modeGest}
    //           />
    //         </div>
    //       </>
    //     ),
    // },
    {
      title: "Article",
      orderable: false,
      render: (orderItem, { responsive }) =>
        responsive ? (
          <div
            className="w-full h-full flex flex-row text-right items-center"
            onClick={() => {
              navigate(
                ROUTES.Product.replace(/:ean/, orderItem.product.id)
                  .replace(/:type/, orderItem.product.modeGest)
                  .replace(
                    /:referGestion/,
                    orderItem.product.referGestion.toString()
                  )
              );
            }}
          >
            {/* <div className="flex justify-center h-full w-8 justify-center">
              <div className="h-full flex ">
                <BookRender
                  src={orderItem.product.imageURL || "/medias/placeholder.png"}
                />
              </div>
            </div> */}
            <div className="w-full h-full flex flex-col">
              <BaseSmall
                data-html={true}
                data-tooltip={getCommonProductTooltip(
                  orderItem.product,
                  orderItem.product.tooltip
                )}
              >
                {orderItem.product.designation}
              </BaseSmall>
              <InfoSmall>{orderItem.product.id}</InfoSmall>
              <InfoSmall className="print:hidden">
                {orderItem.product.qteDispoVenteFutur} dispo vente future (
                {orderItem.product.qteDispoVente} dispo vente,
                {orderItem.product.qtePrepCdePlusTrans} attendue
                commande/transfert (dont {orderItem.product.qteAffecCli}{" "}
                réservés clients))
              </InfoSmall>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-row items-center space-x-4 cursor-pointer"
            onClick={() => {
              navigate(
                ROUTES.Product.replace(/:ean/, orderItem.product.id)
                  .replace(/:type/, orderItem.product.modeGest)
                  .replace(
                    /:referGestion/,
                    orderItem.product.referGestion.toString()
                  )
              );
            }}
          >
            <div className="w-10 print:hidden">
              <BookRender
                src={orderItem.product.imageURL}
                productType={orderItem.product.modeGest}
              />
            </div>

            <div className="flex flex-col">
              <Base
                className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                data-tooltip={getCommonProductTooltip(
                  orderItem.product,
                  orderItem.product.tooltip
                )}
              >
                {orderItem.product.designation}
              </Base>
              <Info>{orderItem.product.id}</Info>
              <InfoSmall className="print:hidden">
                {orderItem.product.qteDispoVenteFutur} disponible à la vente
                future ({orderItem.product.qteDispoVente} disponible à la vente,
                {orderItem.product.qteCdeFournPlusTrans} attendus en commande et
                transfert (dont {orderItem.product.qteReservCli} réservé
                clients))
              </InfoSmall>
            </div>
          </div>
        ),
    },
    {
      title: "Prix TTC",
      id: "prix_vente_ttc",
      orderable: true,
      render: (orderItem) => (
        <Base className="w-full text-end">
          {formatAmount(
            orderItem.pxUnit,
            currentShop?.devisSymb,
            currentShop?.devisNbDecim
          )}
        </Base>
      ),
    },
    {
      title: "Quant.",
      id: "qte_disp",
      orderable: true,
      render: (orderItem) => (
        <div
          key={orderItem.qte}
          className={`flex flex-col orderItems-center gap-1 ${
            props.readonly ? "w-full justify-end" : "justify-center"
          }`}
        >
          {props.readonly ? (
            <Base className="w-full text-end">{orderItem.qte}</Base>
          ) : (
            <div style={{ maxWidth: 128, minWidth: 96 }}>
              <InputCounter
                size={"md"}
                deleteIconOnZero
                value={orderItem.qte}
                onChange={(value) => {
                  const productID = fullProducts.findIndex(
                    (el) => el.product.id === orderItem.product.id
                  );
                  if (value > orderItem.qte) {
                    add(fullProducts[productID].product, value - orderItem.qte);
                  } else {
                    remove(
                      fullProducts[productID].product,
                      orderItem.qte - value
                    );
                  }
                  props.onChange(props.products);
                }}
              />
            </div>
          )}
        </div>
      ),
    },

    {
      title: "Total",
      orderable: true,
      render: (orderItem) => (
        <Base className="w-full text-end">
          {formatAmount(
            orderItem.pxUnit * orderItem.qte,
            currentShop?.devisSymb,
            currentShop?.devisNbDecim
          )}
        </Base>
      ),
    },
    {
      title: location.pathname.includes("basket") ? "Action" : "Statut",
      id: "statu",
      orderable: true,
      render: (orderItem) => {
        if (order) {
          if (
            order.codeCanalVente !== "VAT" &&
            !order.clot &&
            functionsAuthorization[`Cli-${order.codeCanalVente}`].update
          ) {
            return (
              <InputEdit
                fieldType="select"
                className="max-w-36"
                options={statusList
                  .filter((item) =>
                    orderItem.availableStatus.split("|").includes(item.code)
                  )
                  .map((el) => {
                    return { label: el.libelle, value: el.code };
                  })}
                value={orderItem.statu}
                onChange={(e) => {
                  setProductList((previous) => {
                    const pIndex = previous.findIndex(
                      (el) =>
                        el.numLigne === orderItem.numLigne &&
                        el.numLigneDisp === orderItem.numLigneDisp
                    );
                    if (pIndex >= 0) {
                      const pTemp = {
                        ...previous[pIndex],
                        statu: e.target.value,
                      };
                      const tempTab = [...previous];
                      tempTab[pIndex] = pTemp;
                      return tempTab;
                    } else return previous;
                  });
                }}
                onValid={async (newValue) => {
                  const resVerif = await verifOrderLineMaj(
                    order?.numCde || "",
                    {
                      ...orderItem,
                      statu: newValue,
                    }
                  );
                  if (resVerif.response === "OK") {
                    await validMaj(order?.numCde || "", orderItem, newValue);
                    setListUpdate((previousValue) => previousValue + 1);
                    refresh();
                  } else if (resVerif.response === "?") {
                    setConfirmModal({
                      theme: "warning",
                      icon: () => (
                        <FaExclamationTriangle className="text-red-600 h-6 w-6" />
                      ),
                      open: true,
                      title: "",
                      message: resVerif.msg,
                      onAccept: async () => {
                        await validMaj(
                          order?.numCde || "",
                          orderItem,
                          newValue
                        );
                        refresh();
                        setListUpdate((previousValue) => previousValue + 1);
                      },
                      onCancel: async () => handleCancelStatuChange(orderItem),
                    });
                  } else {
                    handleCancelStatuChange(orderItem);

                    setListUpdate((previousValue) => previousValue + 1);
                    toast.error(resVerif.msg);
                  }
                }}
              />
            );
          } else
            return (
              <Base>
                {statusList.find((el) => el.code === orderItem.statu)
                  ?.libelle || ""}
              </Base>
            );
        } else {
          return (
            <Select
              className="shrink-0"
              value={orderItem.statu}
              data-tooltip={`${orderItem.statu} - ${
                orderItem.statu === "ACO"
                  ? "À commander"
                  : orderItem.statu === "RES"
                  ? "Réservation client"
                  : "Prise sur stock"
              }`}
              onChange={(e) => {
                e.stopPropagation();
                const productID = fullProducts.findIndex(
                  (el) => el.product.id === orderItem.product.id
                );

                changeStatu(
                  fullProducts[productID].product,
                  orderItem.qte,
                  e.target.value as "ACO" | "RES" | "STO"
                );

                props.onChange(props.products);
              }}
              /* onChange={(e) => {
              props.onChange(
                props.products.map((p) => {
                  if (p.product.id === orderItem.product.id) {
                    return { ...p, statu: e.target.value };
                  }
                  return p;
                })
              );
            }} */
            >
              <option value="ACO">ACO - À commander</option>
              <option value="RES">RES - Réservation client</option>
              <option value="STO">PS - Prise sur stock</option>
            </Select>
          );
        }
      },
    },
  ];

  if (props.enableBuy) {
    allColumns.push({
      thClassName: "print:hidden",
      className: "print:hidden",
      headClassName: "print:hidden",
      title: "Ajouter au panier",
      orderable: false,
      render: (orderItem) => {
        const basketSize = fullProducts.find(
          (e) => e.product.id === orderItem.product.id
        )?.count;
        if (!basketSize) {
          return (
            <div className="w-full flex justify-center print:hidden">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  //add(orderItem.product, orderItem.qte); --> si on décide d'en ajouter autant que dans la commande
                  add(orderItem.product, 1);
                }}
                icon={(p) => <ShoppingCartIcon {...p} />}
              />{" "}
            </div>
          );
        } else {
          return (
            <InputCounter
              size="md"
              confirmOnZero
              deleteIconOnZero
              value={basketSize || 0}
              onChange={(value) => {
                if (value > basketSize!)
                  add(orderItem.product, value - basketSize!);
                else remove(orderItem.product, basketSize! - value);
              }}
            />
          );
        }
      },
    });
  }

  return (
    <div key={listUpdate} className="grow">
      {statusList.length > 0 && (
        <TableGridSwitch
          gridMode={false}
          data={_.sortBy(productList, (orderItem) => {
            const orderColumn = columns[orderBy[0]];
            let v = (orderItem as any)[orderColumn];
            if (orderColumn === "total") v = orderItem.pxUnit * orderItem.qte;
            return orderBy[1] === "ASC" ? v : -v;
          })}
          total={productList.length}
          initialPagination={{
            order: orderBy[1] === "ASC" ? "ASC" : "DESC",
            orderBy: orderBy[0],
            page: 0,
            perPage: 0,
          }}
          showPagination={false}
          onRequestData={async ({ orderBy, order }) => {
            if (props.order && props.order.numCde !== "") {
              setOrderBy([orderBy || 0, order || "ASC"]);
              const res = await getOrderItems({
                orderBy: allColumns[orderBy || 0].id,
                orderDir: order,
                pageNumber: 1,
                pageSize: 1000,
              });
              if (res) {
                setProductList([...res]);
              }
            }
          }}
          tableColumns={allColumns}
          renderGrid={(p) => (
            <OrderProductCard orderItem={p} editable={!props.readonly} />
          )}
          onFetchExportData={async () => {
            return props.products.map((el, key) => {
              return { id: key, ...el };
            });
          }}
          gridClassName={
            "grid gap-4 grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3"
          }
          subObjectHeader={{
            product: ["id", "designation"],
          }}
        />
      )}
      <div className="grid grid-cols-2 gap-4 mt-2 px-2">
        <div className="text-start flex flex-col justify-center">
          <Section>
            {props.products.reduce((sum, el) => sum + el.qte, 0) || 0} articles
          </Section>
        </div>
        <div className="flex flex-col justify-center text-right">
          <Title>
            {formatAmount(
              props.products.reduce((sum, el) => sum + el.qte * el.pxUnit, 0) ||
                0,
              currentShop?.devisSymb,
              currentShop?.devisNbDecim
            )}
          </Title>
        </div>
      </div>
    </div>
  );
};

export default OrderReview;
