import PdfViewer, { PdfViewerProps } from ".";
import { atom, useRecoilState } from "recoil";
import { Button } from "@atoms/button/button";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Info } from "@atoms/text";
import { createPortal } from "react-dom";

type PdfModalType = {
  open: boolean;
  pdfProps: PdfViewerProps;
  itemList?: any[];
  onNext?: (itemList: any[], index: number) => Promise<Blob | undefined>;
};

export const PdfModal = atom<PdfModalType>({
  key: "PdfModalAtom",
  default: { open: false, pdfProps: { blobFile: null } },
});

export const PdfViewerModal = () => {
  const [pdfModal, setPdfModal] = useRecoilState(PdfModal);
  const [index, setIndex] = useState(0);
  const handleClose = () => {
    setIndex(0);
    setPdfModal({
      open: false,
      pdfProps: { blobFile: null, iFrameClassname: "" },
    });
  };

  const handleNavigate = async (incr: number) => {
    if (
      pdfModal.itemList &&
      pdfModal.itemList[index + incr] &&
      pdfModal.onNext
    ) {
      const nextBlob = await pdfModal.onNext(pdfModal.itemList, index + incr);
      if (nextBlob) {
        setPdfModal({
          ...pdfModal,
          pdfProps: { ...pdfModal.pdfProps, blobFile: nextBlob },
        });
        setIndex((previous) => index + incr);
      }
    }
  };

  useEffect(() => {
    if (
      pdfModal.open &&
      pdfModal.pdfProps.blobFile &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      window.open(
        URL.createObjectURL(pdfModal.pdfProps.blobFile),
        "_blank",
        "noopener,noreferrer"
      );
      setPdfModal({ open: false, pdfProps: { blobFile: null } });
    }
  }, [pdfModal, setPdfModal]);

  if (!pdfModal.open) return null;

  return createPortal(
    <div
      className={pdfModal.open ? "w-full h-full z-50 fixed inset-0" : "hidden"}
      onClick={() => {
        handleClose();
      }}
    >
      <div
        className={
          pdfModal.open
            ? "flex justify-center w-full h-full bg-zinc-600 z-51 opacity-60"
            : "hidden"
        }
      />
      <div className=" w-full h-full flex justify-center z-52 absolute inset-0">
        {pdfModal.pdfProps.blobFile !== null && (
          <PdfViewer {...pdfModal.pdfProps} />
        )}
      </div>
      <div className="z-51 flex gap-2 absolute top-0 right-0 pt-2 pt-2 md:pt-12 md:pr-12">
        {pdfModal.itemList &&
          pdfModal.itemList.length > 1 &&
          pdfModal.onNext && (
            <div className="flex gap-2">
              <Button
                disabled={index === 0}
                theme="default"
                size="md"
                className="!bg-zinc-600 !h-7 !w-7 border-0 !rounded-full text-zinc-300 dark:text-slate-300 hover:opacity-75 focus:outline-none "
                onClick={async () => handleNavigate(-1)}
                icon={(p) => <ChevronLeftIcon {...p} />}
              />
              <div className="">
                <Info>{`${index + 1}/${pdfModal.itemList?.length}`}</Info>
              </div>
              <Button
                disabled={index + 1 >= pdfModal.itemList.length}
                theme="default"
                size="md"
                className="!bg-zinc-600 !h-7 !w-7 border-0 !rounded-full text-zinc-300 dark:text-slate-300 hover:opacity-75 focus:outline-none "
                onClick={async () => handleNavigate(+1)}
                icon={(p) => <ChevronRightIcon {...p} />}
              />
            </div>
          )}

        <Button
          theme="default"
          size="md"
          className="!bg-zinc-600 !h-7 !w-7 border-0 !rounded-full text-zinc-300 dark:text-slate-300 hover:opacity-75 focus:outline-none "
          onClick={() => handleClose()}
          shortcut={["esc"]}
          icon={(p) => <XIcon {...p} />}
        />
      </div>
    </div>,
    document.body
  );
};

export default PdfViewer;
