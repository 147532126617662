import { BookRender } from "@atoms/book";
import { ButtonConfirm } from "@atoms/button/confirm";
import { InputCounter } from "@atoms/input/input-counter";
import { InputEdit } from "@atoms/input/input-edit";
import { Base, BaseBold, Info, InfoBold, InfoSmall } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { ROUTES } from "@features/routes";
import {
  SendOrderHeaders,
  SendOrderLines,
} from "@features/supplier/state/store";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import {
  SupplierOrder,
  SupplierOrderLine,
  SupplierOrderPrepHeader,
  SupplierOrderPrepLine,
} from "@features/supplier/types";
import {
  formatAmount,
  getCommonProductTooltip,
} from "@features/utils/format/strings";
import { XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

export default function SupplierOrderPrepHeaderCard({
  orderPrep,
  modeExpeList,
}: {
  orderPrep: SupplierOrderPrepHeader;
  modeExpeList: any[];
}) {
  const [safeHeader, setSafeHeader] = useState<SupplierOrderPrepHeader>();
  const setOrderHeaders = useSetRecoilState(SendOrderHeaders);
  const {
    getOrderPreparationHeaders,
    updateOrderPreparationLineGrp,
    deleteOrderPreparationLineGrp,
  } = useSuppliers();
  const { current } = useShopLocations();

  const handleUpdateHeader = async (
    orderHeader: SupplierOrderPrepHeader,
    panie: string,
    codeModeExpe: string
  ) => {
    await updateOrderPreparationLineGrp(orderHeader, codeModeExpe, panie);

    const res = await getOrderPreparationHeaders(
      orderPrep.codeTypeProd,
      current?.codeLieu
    );
    if (res) setOrderHeaders(res);
    else setOrderHeaders([]);
  };

  return (
    <div
      className="flex grow h-44 relative overflow-hidden text-ellipsis whitespace-nowrap"
      onClick={() => {}}
    >
      <div className="absolute top-0 right-0">
        <ButtonConfirm
          theme="danger"
          size="sm"
          onClick={async (e) => {
            e.stopPropagation();
            const res = await deleteOrderPreparationLineGrp(orderPrep);
            if (res) toast.success("Commande en préparation supprimée");
            const headers = await getOrderPreparationHeaders(
              orderPrep.codeTypeProd,
              current?.codeLieu
            );
            if (headers) setOrderHeaders(headers);
            else setOrderHeaders([]);
          }}
          icon={({ className }) => <XIcon className={className} />}
          data-tooltip="Supprimer la commande en préparation"
        />
      </div>
      <div className="h-full flex flex-col gap-2 p-2 ">
        <BaseBold>
          {orderPrep.raisSocial} (
          <Base className="overflow-hidden text-ellipsis whitespace-nowrap">
            {orderPrep.codeFourn})
          </Base>
        </BaseBold>
        <div className="h-full flex flex-col gap-2 px-2">
          <div className="w-full flex gap-2 justify-between items-center">
            <InfoBold>Panier:</InfoBold>
            <InputEdit
              inputClassName="min-w-20"
              value={orderPrep.panie}
              options={[]}
              onStartEdit={() => setSafeHeader({ ...orderPrep })}
              onStopEdit={() => setSafeHeader(undefined)}
              onChange={(e) => {
                setOrderHeaders((previous) => {
                  const tempTab = [...previous];
                  const lineIndex = previous.findIndex(
                    (el) => el.find === orderPrep.find
                  );
                  const tempLine = { ...tempTab[lineIndex] };
                  tempLine.panie = e.target.value;
                  tempTab[lineIndex] = tempLine;
                  return tempTab;
                });
              }}
              onValid={async (newValue) => {
                handleUpdateHeader(
                  { ...orderPrep, panie: safeHeader!.panie },
                  newValue,
                  safeHeader!.codeModeExpe
                );
                return undefined;
              }}
            />
          </div>
          <div className="w-full flex gap-2 justify-between items-center">
            <InfoBold>Mode d'expédition:</InfoBold>

            <InputEdit
              value={orderPrep.codeModeExpe}
              fieldType="select"
              options={modeExpeList}
              onStartEdit={() => setSafeHeader({ ...orderPrep })}
              onStopEdit={() => setSafeHeader(undefined)}
              onChange={(e) => {
                setOrderHeaders((previous) => {
                  const tempTab = [...previous];
                  const lineIndex = previous.findIndex(
                    (el) => el.find === orderPrep.find
                  );
                  const tempLine = { ...tempTab[lineIndex] };
                  tempLine.codeModeExpe = e.target.value;
                  tempTab[lineIndex] = tempLine;
                  return tempTab;
                });
              }}
              onValid={async (newValue) => {
                handleUpdateHeader(
                  { ...orderPrep, codeModeExpe: safeHeader!.codeModeExpe },
                  safeHeader!.panie,
                  newValue
                );
                return undefined;
              }}
            />
          </div>
          <div>
            <InfoBold> Franco ({orderPrep.francPort}): </InfoBold>
            <Info
              className={
                orderPrep.francoAtteint ? "!text-green-600" : "!text-red-600"
              }
            >
              {orderPrep.francoAtteint ? "Atteint" : "Non atteint"}
            </Info>
          </div>

          <div>
            <InfoBold>Nombres d'articles: </InfoBold>
            <Info className="!text-white"> {orderPrep.nbrLigne}</Info>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SupplierOrderPrepProductCard({
  product,
  modeGest,
  modeExpeList,
}: {
  product: SupplierOrderPrepLine;
  modeGest: "DISQ" | "LIVR" | "PRES" | "PAPE";
  modeExpeList: any[];
}) {
  const {
    selectedPrepHeader,
    changeSelectedPrepHeader,
    getOrderPreparationLines,
    updateOrderPreparationLine,
    deleteOrderPreparationLine,
  } = useSuppliers();
  const setOrderLines = useSetRecoilState(SendOrderLines);

  const { current } = useShopLocations();
  const navigate = useNavigate();

  const handleUpdateLine = async (
    orderLine: SupplierOrderPrepLine,
    panie: string,
    codeModeExpe: string,
    qte: number
  ) => {
    await updateOrderPreparationLine(orderLine, codeModeExpe, panie, qte);
    changeSelectedPrepHeader({
      ...selectedPrepHeader,
      panie: panie,
      codeModeExpe: codeModeExpe,
    });
    const res = await getOrderPreparationLines(
      product.codeTypeProd || "LIVR",
      selectedPrepHeader.codeFourn,
      selectedPrepHeader.codeLieuLivr,
      panie,
      codeModeExpe
    );
    if (res) {
      if (res.length === 0) navigate(ROUTES.SupplierSendOrderPage);
      setOrderLines(res);
    } else setOrderLines([]);
  };

  return (
    <div
      className="flex flex-col grow h-56 relative "
      onClick={() => {
        navigate(
          ROUTES.Product.replace(/:ean/, product.ean13).replace(
            /:type/,
            product.tooltip.modeGest
          )
        );
      }}
    >
      <div className="h-14 flex flex-col justify-center absolute top-0 right-1 z-40">
        <ButtonConfirm
          id="DANGER"
          theme="danger"
          size="sm"
          onClick={async (e) => {
            e.stopPropagation();
            const res = await deleteOrderPreparationLine(product);
            if (res)
              toast.success("Ligne de commande en préparation supprimée");
            const lines = await getOrderPreparationLines(
              product.codeTypeProd,
              selectedPrepHeader.codeFourn,
              selectedPrepHeader.codeLieuLivr,
              selectedPrepHeader.panie,
              selectedPrepHeader.codeModeExpe
            );
            if (lines) {
              if (lines.length === 0) navigate(ROUTES.SupplierSendOrderPage);
              setOrderLines(lines);
            } else setOrderLines([]);
          }}
          icon={({ className }) => <XIcon className={className} />}
          data-tooltip="Supprimer la commande en préparation"
        />
      </div>
      <div className="w-full relative mb-2 px-4 py-2 flex h-14 gap-8 bg-blue-600 opacity-60 dark:opacity-100 dark:bg-slate-700 rounded-tl-md rounded-tr-md">
        <div
          className="px-2 flex items-center justify-center"
          data-tooltip={`<img
                src="${product.imageURL}"     
                style="max-width: 400px; max-height: 400px; width: 100%; height: auto;"/>`}
          data-position="right"
        >
          <BookRender src={product.imageURL} productType={modeGest} />
        </div>
        <div className="w-auto max-w-sm flex flex-col pb-2">
          <InfoBold
            className="block overflow-hidden whitespace-nowrap text-ellipsis cursor-text truncate !text-black dark:!text-white"
            data-html={true}
            data-tooltip={getCommonProductTooltip(product, product.tooltip)}
          >
            {product.desig}
          </InfoBold>
          <InfoSmall className="block cursor-text">{product.ean13}</InfoSmall>
        </div>
      </div>
      <div className="h-full flex gap-2">
        <div className="grow flex flex-col gap-2 px-2 overflow-hidden ">
          <InfoBold className="block cursor-text">
            Fournisseur:
            <InfoSmall>{product.codeFourn}</InfoSmall>
          </InfoBold>
          <InfoBold className="block cursor-text">
            Rayon:
            <InfoSmall>{product.codeRayon}</InfoSmall>
          </InfoBold>

          <InfoBold className="block cursor-text">
            Quantité disponible à la vente:
            <InfoSmall>{product.qteDispoVente}</InfoSmall>
          </InfoBold>
        </div>

        <div className="self-center h-3/4 w-px bg-gray-400 rounded-md"></div>
        <div className="h-full flex flex-col gap-2 px-2 w-2/3 ">
          <div className="w-full flex gap-2 justify-between ">
            <InfoBold className="w-full block cursor-text ">Panier:</InfoBold>
            <InputEdit
              size="sm"
              inputClassName="min-w-20"
              value={product.panie}
              options={[]}
              onChange={(e) => {
                setOrderLines((previous) => {
                  const tempTab = [...previous];
                  const lineIndex = previous.findIndex(
                    (el) => el.cle === product.cle
                  );
                  const tempLine = { ...tempTab[lineIndex] };
                  tempLine.panie = e.target.value;
                  tempTab[lineIndex] = tempLine;
                  return tempTab;
                });
              }}
              onValid={async (newValue) => {
                await handleUpdateLine(
                  { ...product, panie: selectedPrepHeader.panie },
                  newValue,
                  product.codeModeExpe,
                  product.qte
                );
              }}
            />
          </div>
          <div className="w-full flex gap-2 justify-between items-center ">
            <InfoBold className="w-full block cursor-text">
              Mode d'expédition:
            </InfoBold>
            <InputEdit
              size="sm"
              value={product.codeModeExpe}
              fieldType="select"
              options={modeExpeList}
              onChange={(e) => {
                setOrderLines((previous) => {
                  const tempTab = [...previous];
                  const lineIndex = previous.findIndex(
                    (el) => el.cle === product.cle
                  );
                  const tempLine = { ...tempTab[lineIndex] };
                  tempLine.codeModeExpe = e.target.value;
                  tempTab[lineIndex] = tempLine;
                  return tempTab;
                });
              }}
              onValid={async (newValue) => {
                handleUpdateLine(product, product.panie, newValue, product.qte);
              }}
            />
          </div>
          <div className="w-full flex gap-2 justify-between items-center">
            <InfoBold className="w-full block cursor-text">Quantité:</InfoBold>
            <div className="text-end" style={{ maxWidth: 128, minWidth: 96 }}>
              <InputCounter
                size="sm"
                value={product.qte}
                onChange={async (newValue) => {
                  await handleUpdateLine(
                    { ...product, panie: selectedPrepHeader.panie },
                    product.panie,
                    product.codeModeExpe,
                    newValue
                  );
                }}
              />
            </div>
          </div>
          <div className="my-2 w-full text-end">
            <BaseBold className="!text-blue-600">
              {`${formatAmount(
                product.prixBase,
                current?.devisSymb,
                current?.devisNbDecim
              )}`}
              <InfoSmall className="text-center"> TTC</InfoSmall>
            </BaseBold>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SupplierOrderHeaderCard({
  order,
  statusList,
  modeGest,
}: {
  order: SupplierOrder;
  statusList: any[];
  modeGest: string;
}) {
  const { current } = useShopLocations();

  return (
    <div
      className="flex grow h-44 relative overflow-hidden text-ellipsis whitespace-nowrap"
      onClick={() => {}}
    >
      <div className="flex flex-col gap-2">
        <BaseBold>{order.numCde}</BaseBold>
        <BaseBold>{order.codeFourn}</BaseBold>
        <InfoBold className="block cursor-text">
          {"Panier: "}
          <InfoSmall>{order.panie}</InfoSmall>
        </InfoBold>
        <InfoBold className="block cursor-text">
          {"Quantité: "}
          <InfoSmall>{order.statuCde}</InfoSmall>
        </InfoBold>
        <InfoBold className="block cursor-text">
          {"Références: "}
          <InfoSmall>{order.nbRef}</InfoSmall>
        </InfoBold>
        <InfoBold className="block cursor-text">
          {"Status: "}
          <InfoSmall>
            {statusList.map((el) => {
              if (order.statuCde === el.code)
                return `${el.libelle} (${el.code})`;
              else return null;
            })}
          </InfoSmall>
        </InfoBold>
        <InfoBold className="block cursor-text">
          {"Mt. brut: "}
          <InfoSmall>
            {formatAmount(
              order.mtBase,
              current?.devisSymb,
              modeGest === "P" ? current?.devisNbDecimP : current?.devisNbDecim
            )}
          </InfoSmall>
        </InfoBold>
        <InfoBold className="block cursor-text">
          {"Mt. net: "}
          <InfoSmall>
            {formatAmount(
              order.mtNet,
              current?.devisSymb,
              modeGest === "P" ? current?.devisNbDecimP : current?.devisNbDecim
            )}
          </InfoSmall>
        </InfoBold>
      </div>
    </div>
  );
}

export function SupplierOrderProductCard({
  product,
  modeGest,
}: {
  product: SupplierOrderLine;
  modeGest: string;
}) {
  const { current } = useShopLocations();
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col grow h-32 relative "
      onClick={() => {
        navigate(
          ROUTES.Product.replace(/:ean/, product.ean13).replace(
            /:type/,
            product.tooltip.modeGest
          )
        );
      }}
    >
      <div className="w-full relative mb-2 px-4 py-2 flex h-14 gap-8 bg-blue-600 opacity-60 dark:opacity-100 dark:bg-slate-700 rounded-tl-md rounded-tr-md">
        <div
          className="px-2 flex items-center justify-center"
          data-tooltip={`<img
                src="${product.imageURL}"     
                style="max-width: 400px; max-height: 400px; width: 100%; height: auto;"/>`}
          data-position="right"
        >
          <BookRender src={product.imageURL} productType={modeGest} />
        </div>
        <div className="w-auto max-w-xs flex flex-col pb-2">
          <InfoBold
            className="block overflow-hidden whitespace-nowrap text-ellipsis cursor-text truncate !text-black dark:!text-white"
            data-html={true}
            data-tooltip={getCommonProductTooltip(product, product.tooltip)}
          >
            {product.desig}
          </InfoBold>
          <InfoSmall className="block cursor-text">{product.ean13}</InfoSmall>
        </div>
      </div>

      <div className="h-full flex gap-2">
        <div className="grow flex flex-col gap-2 px-2 overflow-hidden ">
          <InfoBold className="block cursor-text">
            {"Quantité: "}
            <InfoSmall>{product.qte}</InfoSmall>
          </InfoBold>
          <InfoBold className="block cursor-text">
            {"Opé. : "}
            <InfoSmall>
              {product.codeOpe ? product.codeOpe : "Non renseigné"}
            </InfoSmall>
          </InfoBold>
        </div>

        <div className="h-full flex flex-col gap-2 px-2 w-2/3 ">
          <div className="w-full flex gap-2 justify-between items-center"></div>
          <div className="my-2 w-full text-end">
            <BaseBold className="!text-blue-600">
              {`${formatAmount(
                product.prixBase,
                current?.devisSymb,
                current?.devisNbDecim
              )}`}
              <InfoSmall className="text-center"> TTC</InfoSmall>
            </BaseBold>
          </div>
        </div>
      </div>
    </div>
  );
}
