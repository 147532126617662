import { Button } from "@atoms/button/button";
import { PageLoader } from "@atoms/layout/page-loader";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, BaseBold, Title } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { SendOrderHeaders } from "@features/supplier/state/store";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { SupplierOrderPrepHeader } from "@features/supplier/types";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { PrinterIcon } from "@heroicons/react/outline";
import { PdfModal } from "@molecules/pdf-viewer/pdf-viewer-modal";
import { Table } from "@molecules/table";
import { useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

type SendMultiOrderModalType = {
  open: boolean;
  orders: SupplierOrderPrepHeader[];
  mode?: string;
};

export const SendMultiOrderModal = atom<SendMultiOrderModalType>({
  key: "SendMultiOrderModalAtom",
  default: { open: false, orders: [] },
});

export const SendMultiSupplierOrderModal = () => {
  const [sendMultiOrderModal, setSentMultiOrderModal] =
    useRecoilState(SendMultiOrderModal);
  const {
    loading: supplierLoading,
    getOrderPreparationHeaders,
    createOrderBrouillard,
    getOrderBrouillard,
    getSupplierDocument,
    sendFournOrder,
  } = useSuppliers();
  const setOrderHeaders = useSetRecoilState(SendOrderHeaders);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const [success, setSuccess] = useState<SupplierOrderPrepHeader[]>([]);
  const [fails, setFails] = useState<SupplierOrderPrepHeader[]>([]);
  //const [tempBrouiList, setTempBrouiList] = useState<OrderPrepBrouillard[]>([]);
  const [tempNumCdeList, setTempNumCdeList] = useState<any[]>([]);

  const setPdfModal = useSetRecoilState(PdfModal);

  const { current } = useShopLocations();

  const handleClose = () => {
    setSentMultiOrderModal({ open: false, orders: [] });
    //setTempBrouiList([]);
    setTempNumCdeList([]);
    setLoading(false);
    setCounter(0);
    setSuccess([]);
    setFails([]);
  };

  const handleSend = async (fournMode: boolean = false) => {
    setLoading(true);
    sendMultiOrderModal.orders.map(async (el) => {
      try {
        setCounter((previous) => previous + 1);
        let res = undefined;
        if (!fournMode)
          res = await createOrderBrouillard(
            el.codeTypeProd,
            el.codeLieuPourQui,
            el.niv,
            el,
            true
          );
        if (res || fournMode) {
          const brouil = await getOrderBrouillard(
            el.codeTypeProd,
            el.codeLieuPourQui,
            el.niv,
            el
          );
          if (brouil) {
            //setTempBrouiList((previous) => [...previous, brouil]);
            const resEnv = await sendFournOrder(
              el.codeTypeProd,
              el.codeLieuPourQui,
              el.niv,
              el,
              brouil
            );
            if (resEnv) {
              toast.success("Commande envoyée avec succès");
              setSuccess((previous) => {
                const temp = [...previous];
                temp.push(el);
                return temp;
              });
              setTempNumCdeList((previous) => [
                ...previous,
                { ...el, numCde: resEnv.numCde },
              ]);
            }
          }
        }
      } catch {
        toast.error("Erreur lors de l'envoi");
        setFails((previous) => {
          const temp = [...previous];
          temp.push(el);
          return temp;
        });
      }
    });
  };

  useControlledEffect(() => {
    const sendFunction = async () => {
      const customEvent = new CustomEvent("sendOrderEvent");
      try {
        setLoading(true);
        await handleSend();
        setLoading(false);
        window.dispatchEvent(customEvent);
      } catch (err) {
        setLoading(false);
        window.dispatchEvent(customEvent);
        toast.error("Une erreur est survenue");
      }
    };
    if (sendMultiOrderModal.mode === "envFourn") sendFunction();
  }, [sendMultiOrderModal]);

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={sendMultiOrderModal.open}
      onClose={async () => handleClose()}
      loading={loading || supplierLoading}
    >
      <ModalContent title={"Paramètres d'envoi de commande(s)"}>
        <div className="grow">
          {loading && (
            <div className="w-full h-full flex gap-4 justify-center items-center">
              <PageLoader />
              <Title className="h-full !text-gray-500">Envoi en cours</Title>
              <Title className="h-full !text-gray-500">{`${counter}/${sendMultiOrderModal.orders.length}`}</Title>
            </div>
          )}

          {!loading && counter === 0 && (
            <div className="w-full flex flex-col gap-4 text-center">
              <Base>
                {`Vous êtes sur le point d'envoyer ${sendMultiOrderModal.orders.length} commandes. Souhaitez vous continuer?`}
              </Base>
              <div className="w-full flex gap-4 justify-center">
                <Button
                  theme="default"
                  className="self-center"
                  icon={(p) => <PrinterIcon {...p} />}
                  onClick={async () => {
                    let modeGest: string = "";
                    if (current?.items) {
                      modeGest =
                        current.items.find(
                          (el) =>
                            el.codeTypeProd ===
                            sendMultiOrderModal.orders[0].codeTypeProd
                        )?.modeGest || "P";
                    }

                    const createRes = await createOrderBrouillard(
                      sendMultiOrderModal.orders[0].codeTypeProd,
                      sendMultiOrderModal.orders[0].codeLieuPourQui,
                      sendMultiOrderModal.orders[0].niv,
                      sendMultiOrderModal.orders[0],
                      true
                    );

                    if (createRes) {
                      const brouil = await getOrderBrouillard(
                        sendMultiOrderModal.orders[0].codeTypeProd,
                        sendMultiOrderModal.orders[0].codeLieuPourQui,
                        sendMultiOrderModal.orders[0].niv,
                        sendMultiOrderModal.orders[0]
                      );

                      if (brouil) {
                        const res = await getSupplierDocument(
                          brouil.numBroui,
                          "brouiCde",
                          modeGest || "P",
                          sendMultiOrderModal.orders[0].codeTypeProd,
                          current?.codeLieu || "",
                          current?.centr || false
                        );
                        if (res) {
                          setPdfModal({
                            open: true,
                            pdfProps: {
                              blobFile: res,
                              iframeSize: {
                                width: "80%",
                                height: "90%",
                              },
                              iFrameClassname:
                                "w-full h-full flex justify-center items-center",
                            },
                            itemList: sendMultiOrderModal.orders,
                            onNext: async (itemList, index) => {
                              const res = await createOrderBrouillard(
                                itemList[index].codeTypeProd,
                                itemList[index].codeLieuPourQui,
                                itemList[index].niv,
                                itemList[index],
                                true
                              );
                              if (res) {
                                const brouil = await getOrderBrouillard(
                                  itemList[index].codeTypeProd,
                                  itemList[index].codeLieuPourQui,
                                  itemList[index].niv,
                                  itemList[index]
                                );
                                if (brouil) {
                                  const resBis = await getSupplierDocument(
                                    brouil.numBroui,
                                    "brouiCde",
                                    modeGest || "P",
                                    sendMultiOrderModal.orders[0].codeTypeProd,
                                    current?.codeLieu || "",
                                    current?.centr || false
                                  );
                                  return resBis;
                                }
                              }
                            },
                          });
                        }
                      }
                    }
                  }}
                >
                  Imprimer brouillards
                </Button>
                <Button
                  theme="valid"
                  className="self-center"
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await handleSend();
                    } catch {
                      toast.error("ERROR");
                      //setLoading(false);
                    } finally {
                      setTimeout(async () => {
                        const res = await getOrderPreparationHeaders(
                          sendMultiOrderModal.orders[0].codeTypeProd,
                          current?.codeLieu
                        );
                        if (res) setOrderHeaders(res);
                        else setOrderHeaders([]);
                        setLoading(false);
                      }, 2000);
                    }
                  }}
                >
                  Envoyer
                </Button>
              </div>
            </div>
          )}
          {!loading && counter === sendMultiOrderModal.orders.length && (
            <div className="flex flex-col gap-4">
              <Base>Succès: {success.length}</Base>
              <Base>Erreurs: {fails.length}</Base>
              {fails.length > 0 && (
                <div className="flex flex-col gap-2">
                  <BaseBold className="self-center">
                    Liste des commandes ayant rencontré un problème:
                  </BaseBold>
                  <Table
                    name="truc"
                    columns={[
                      {
                        title: "Fournisseur",
                        id: "codeFourn",
                        render: (p) => <Base>{p.codeFourn}</Base>,
                      },
                      {
                        title: "Raison-sociale",
                        id: "raisSocial",
                        render: (p) => <Base>{p.raisSocial}</Base>,
                      },
                      {
                        title: "Panier",
                        id: "panie",
                        render: (p) => <Base>{p.panie}</Base>,
                      },
                      {
                        title: "Mode exp.",
                        id: "codeModeExpe",
                        render: (p) => <Base>{p.codeModeExpe}</Base>,
                      },
                    ]}
                    data={[...fails]}
                  />
                </div>
              )}
              <div className="w-full flex gap-4">
                <Button
                  disabled={tempNumCdeList.length === 0}
                  theme="default"
                  className="self-center"
                  icon={(p) => <PrinterIcon {...p} />}
                  onClick={async () => {
                    let modeGest: string = "";
                    if (current?.items) {
                      modeGest =
                        current.items.find(
                          (el) =>
                            el.codeTypeProd ===
                            sendMultiOrderModal.orders[0].codeTypeProd
                        )?.modeGest || "P";
                    }
                    const res = await getSupplierDocument(
                      tempNumCdeList[0].numCde,
                      "cde",
                      modeGest || "P",
                      tempNumCdeList[0].codeTypeProd,
                      current?.codeLieu || "",
                      current?.centr || false
                    );
                    if (res) {
                      setPdfModal({
                        open: true,
                        pdfProps: {
                          blobFile: res,
                          iframeSize: {
                            width: "80%",
                            height: "90%",
                          },
                          iFrameClassname:
                            "w-full h-full flex justify-center items-center",
                        },
                        itemList: tempNumCdeList,
                        onNext: async (itemList, index) => {
                          const resBis = await getSupplierDocument(
                            itemList[index].numCde,
                            "cde",
                            modeGest || "P",
                            itemList[index].numCde,
                            current?.codeLieu || "",
                            current?.centr || false
                          );
                          return resBis;
                        },
                      });
                    }
                  }}
                >
                  Imprimer bons de commande
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};
