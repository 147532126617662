import { FullScreenPage } from "@atoms/layout/page";
import Link from "@atoms/link";
import { Loader } from "@atoms/loader";
import { Info, BaseBold, Section } from "@atoms/text";
import SingleCenterCard from "@atoms/layout/centered-card";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { formatTime } from "@features/utils/format/dates";
import { ChevronRightIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { SearchProductsAtom } from "@features/products/state/store";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import Env from "@config/environment";

export const LocationsPage = () => {
  const {
    locations,
    loading,
    refresh,
    save: saveCurrentLocation,
  } = useShopLocations();
  const navigate = useNavigate();

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (loading && !locations?.length) {
    return (
      <FullScreenPage>
        <div className="flex items-center justify-center w-screen absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Loader />
        </div>
      </FullScreenPage>
    );
  }

  if (!loading && !locations?.length) {
    return (
      <FullScreenPage>
        <div className="flex items-center justify-center w-screen absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Info className="text-center">
            Aucun lieu de travail n'est associé à votre compte.
          </Info>
        </div>
      </FullScreenPage>
    );
  }

  const lastLocation = _.orderBy(
    locations,
    (l) => new Date(l.dateDernCon).getTime(),
    "desc"
  )[0];

  return (
    <div className="flex w-full grow flex-col bg-slate-50 dark:bg-slate-900 h-full">
      <FullScreenPage>
        <SingleCenterCard>
          <div className="text-center mb-12">
            <Section>Sélectionnez un lieu</Section>
            <Info className="text-center">
              Dernière connexion sur{" "}
              <Link
                onClick={() => {
                  saveCurrentLocation(lastLocation);
                  navigate("/");
                }}
              >
                {lastLocation.libLieu}
              </Link>
            </Info>
          </div>

          <Location />
        </SingleCenterCard>
      </FullScreenPage>
    </div>
  );
};

export const Location = () => {
  const {
    locations,
    save: saveCurrentLocation,
    getFunctionsAuthorizations,
  } = useShopLocations();

  const setProducts = useSetRecoilState(SearchProductsAtom);
  const [, setProductSearchFilters] = usePersistedState<any>(
    "user",
    "product-filters",
    {
      type: "all",
      external_db: Env.defaultExternalDB,
    }
  );
  const navigate = useNavigate();

  const orderedLocations = _.orderBy(
    locations,
    (l) => new Date(l.dateDernCon).getTime(),
    "desc"
  );

  return (
    <div>
      {orderedLocations.map((el, index) => {
        return (
          <ListRow
            key={index}
            className="bg-slate-100 dark:bg-slate-700 flex items-center px-3 py-2 mt-2 mb-2 hover:ring-slate-500 hover:ring-2 hover:ring-offset-2 hover:cursor-pointer rounded-md"
            onClick={async () => {
              setProductSearchFilters({
                type: "all",
                external_db: Env.defaultExternalDB,
              });
              setProducts({
                pageCount: 0,
                totalRows: 0,
                pageNumber: 0,
                pageSize: 0,
                items: [],
              });
              saveCurrentLocation(el);
              await getFunctionsAuthorizations(el.codeProf, "PV");
              navigate("/");
              window.location.reload();
            }}
            text={`${el.libLieu} - ${el.codeLieu}`}
            subtitle={formatTime(el.dateDernCon) + ", " + el.libProf}
            button={
              <ChevronRightIcon className="h-5 text-black dark:text-white" />
            }
          />
        );
      })}
    </div>
  );
};

const ListRow = (props: {
  className?: string;
  prefix?: ReactNode;
  text: ReactNode | string;
  subtitle?: ReactNode | string;
  button?: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div onClick={props.onClick} className={props.className}>
      {props.prefix}
      <div className="grow group">
        <BaseBold>
          {props.text}
          <br />
          {<Info>{props.subtitle}</Info>}
        </BaseBold>
      </div>
      <div className="shrink-0">{props.button}</div>
    </div>
  );
};
