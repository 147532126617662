import { Title } from "@atoms/text";
import { Table } from "@molecules/table";
import { Column, Pagination } from "@molecules/table/table";
import { useState } from "react";
import { createPortal } from "react-dom";
import { atom, useRecoilState } from "recoil";

type TabVertModalType = {
  open: boolean;
  columns: Column<any>[];
  title: string;
  onTabClick?: (el: any) => void;
  getData?: (
    page: number,
    perPage: number,
    order: "ASC" | "DESC" | undefined,
    orderBy: number | undefined,
    filters: any
  ) => Promise<{
    items: any[];
    pageNumber: number;
    pageSize: number;
    pageCount: number;
    totalRows: number;
    rowsReturned: number;
  }>;
};

export const TabVertModalAtom = atom<TabVertModalType>({
  key: "TabVertModal",
  default: { open: false, title: "", columns: [] },
});

export const TabVertModal = () => {
  const [tabVertModal, setTabVertModal] = useRecoilState(TabVertModalAtom);
  const [order, setOrder] = useState<{
    orderDir: "ASC" | "DESC" | undefined;
    orderBy?: string;
  }>({ orderDir: "ASC", orderBy: undefined });
  const [data, setData] = useState<{
    items: any[];
    pageNumber: number;
    pageSize: number;
    pageCount: number;
    totalRows: number;
    rowsReturned: number;
  }>();

  const handleClose = () => {
    setTabVertModal((prev) => ({
      ...prev,
      open: false,
      getData: undefined,
      onTabClick: undefined,
    }));

    setTimeout(() => {
      setData({
        items: [],
        pageNumber: 1,
        pageSize: 15,
        pageCount: 1,
        totalRows: 0,
        rowsReturned: 0,
      });
      setTabVertModal({
        open: false,
        columns: [],
        getData: undefined,
        title: tabVertModal.title || "",
      });
    }, 300); // Délai pour permettre l'animation
  };

  return createPortal(
    <div
      className={`fixed inset-0 z-50 grid place-items-center min-h-screen my-4 bg-opacity-50 ${
        tabVertModal.open
          ? "opacity-100 scale-100"
          : "hidden opacity-0 scale-95"
      } transition-all duration-300`}
    >
      {tabVertModal.open && (
        <div className="bg-white dark:bg-slate-900 rounded-lg shadow-lg w-3/4 max-w-[1400px] max-h-[800px] p-6 transform transition-all duration-300">
          <button
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
            onClick={handleClose}
          >
            ✖
          </button>

          <div className="max-h-[750px] flex flex-col">
            <Title>{tabVertModal.title}</Title>
            <Table
              className="w-full overflow-x-scroll"
              showPagination={"full"}
              columns={tabVertModal.columns}
              data={data?.items || []}
              searchModeEnabled={true}
              total={data?.totalRows || 0}
              onClick={(el) => {
                tabVertModal.onTabClick && tabVertModal.onTabClick(el);
                handleClose();
              }}
              initialPagination={
                {
                  total: 0,
                  page: 1,
                  perPage: 10,
                  order: order.orderDir,
                } as Pagination
              }
              onRequestData={async ({
                page,
                perPage,
                order,
                orderBy,
                filters,
              }) => {
                if (tabVertModal.getData) {
                  const res = await tabVertModal.getData(
                    page,
                    perPage,
                    order,
                    orderBy,
                    filters
                  );
                  setOrder({ orderDir: order });
                  if (res) setData({ ...res });
                }
              }}
              onChangeFilters={async (filters) => {
                if (tabVertModal.getData) {
                  const res = await tabVertModal.getData(
                    1,
                    15,
                    "ASC",
                    0,
                    filters
                  );
                  if (res) setData({ ...res });
                }
              }}
            />
          </div>
        </div>
      )}
    </div>,
    document.body
  );
};
