import { PageLoader } from "@atoms/layout/page-loader";
import { useAuth } from "@features/general/auth/state/use-auth";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { Header } from "@views/_layout/header";
import { useCallback, useRef } from "react";
import { Outlet, Route, useLocation, useNavigate } from "react-router-dom";
import { LoginPage } from "./login";
import { RecoverPasswordPage } from "@views/reset-password";

export const useAutoNavigateToLogin = async () => {
  const {
    locations,
    refresh,
    save,
    getFunctionsAuthorizations,
    current: currentShopLocation,
  } = useShopLocations();
  const { user, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const wasLoggedOffRef = useRef(false);

  const redirectToApp = useCallback(() => {
    navigate(
      decodeURIComponent(
        new URL((window as any).document.location).searchParams.get("r") || ""
      ) || ROUTES.Home
    );
  }, [navigate]);

  useControlledEffect(() => {
    if (
      user?.id &&
      !currentShopLocation &&
      location.pathname !== ROUTES.Locations
    )
      refresh();
  }, [user?.id, currentShopLocation?.codeLieu, location]);

  useControlledEffect(() => {
    const fetchFunctionsAuth = async (profil: string, typeLieu: string) => {
      await getFunctionsAuthorizations(profil, typeLieu);
    };
    if (locations.length > 0 && currentShopLocation == null) {
      save(locations[0]);
      fetchFunctionsAuth(locations[0].codeProf, "PV");
      navigate("/");
    }
  }, [locations]);

  useControlledEffect(() => {
    if (loading) return;
    if (!user?.id) {
      wasLoggedOffRef.current = true;
    }
    if (wasLoggedOffRef.current || (user?.id && currentShopLocation)) {
      if (user?.id && !!currentShopLocation) redirectToApp();
    }
  }, [user?.id, loading]);
};

export const LoginRoutes = () => {
  return (
    <Route element={<LoginLayout />}>
      <Route path={ROUTES.Login} element={<LoginPage />} />
      <Route path={ROUTES.Recover} element={<RecoverPasswordPage />} />
    </Route>
  );
};

export const LoginLayout = () => {
  const { loading } = useAuth();

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="flex w-full grow flex-col bg-white dark:bg-slate-900 h-full">
      <Header />
      <div className="grow min-h-0 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};
